import React from "react";
import Layout from "@/components/layout";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import {Col, Nav, Row, Tab} from 'react-bootstrap';


import baked1 from "../assets/img/Baked1.png";
import baked2 from "../assets/img/Baked2.png";
import baked3 from "../assets/img/Baked3.png";
import lease1 from "../assets/img/lease.png";
import lease2 from "../assets/img/lease2.png";
import safety from "../assets/img/safety.png";
import pipeline from "../assets/img/pipeline.png";
import yield2 from "../assets/img/yield.jpeg";
import stop from "../assets/img/stop.jpeg";
import traffic1 from "../assets/img/Traffic2.jpeg";
import traffic2 from "../assets/img/Traffic3.jpeg";


const SignBlanks = () => {
    return (
        <MenuContextProvider>
            <Layout PageTitle="Sign Blanks">
                <HeaderOne/>
                <PageHeader title="Sign Blanks" name="Sign Blanks"/>
                <div className="sec-title text-center">
                    <p className={"m-5 p-5 "}>
                        We offer a variety of sign blanks for your signage needs. <br/> Many of our signs are cut daily
                        for next-day shipping and we offer pre-printed 3M HI INT stop and yield signs.
                    </p>
                </div>

                <div className="tab-wrapper m-5">
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-sm-12">

                                <Tab.Container defaultActiveKey="bakedenamel">
                                    <Row>
                                        <Col sm={3}>

                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="bakedenamel">Baked Enamel</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="leasesigns">Oil & Gas Industry</Nav.Link>
                                                </Nav.Item>

                                                <Nav.Item>
                                                    <Nav.Link eventKey="traffic">Traffic</Nav.Link>
                                                </Nav.Item>

                                            </Nav>

                                        </Col>
                                        <Col sm={7}>
                                            <Tab.Content>

                                                <Tab.Pane eventKey="bakedenamel">
                                                    <h5>Baked Enamel</h5>
                                                    <p>
                                                        Our Baked Enamel sign blanks are pre-painted white,
                                                        cut to size, poly coated on one side, and graphic ready.

                                                        Newby Enterprises cuts our Baked Enamel signs daily for
                                                        next day shipping on most orders. We can also cut and
                                                        laminate custom sizes and colors to fit your needs.
                                                        If you know what you’d like to order, request a quote.
                                                        If you’d like to chat about your options, get in touch.
                                                    </p>
                                                    <img src={baked1} alt=""/>
                                                    <img src={baked2} alt=""/>
                                                    <img src={baked3} alt=""/>

                                                </Tab.Pane>

                                                <Tab.Pane eventKey="leasesigns">

                                                    <h5>Lease Signs</h5>
                                                    <p>

                                                        Our Lease Signs are 60x 30cm x .080" with 1/4" cropped
                                                        corners and six 1/4" holes.<br/><br/>

                                                        The center holes are aligned to fit both our light and
                                                        heavy duty posts.<br/><br/>


                                                        You can purchase 60 x 30cm Lease Blanks with either a
                                                        Degreased or White Vinyl Finish.
                                                    </p>
                                                    <img src={lease1} alt=""/>
                                                    <img src={lease2} alt=""/>


                                                    <h5>Safety Signs</h5>
                                                    <p>

                                                        Our Safety Signs are 25 x 35cm x .040" and they come with
                                                        six 1/4" holes.
                                                        <br/><br/>
                                                        You can purchase Safety Blanks with either a Degreased
                                                        or White Vinyl Finish. </p>
                                                    <img src={safety} alt=""/>

                                                    <h5>Pipeline Signs</h5>
                                                    <p>

                                                        Our Pipeline Signs are 15 x 35cm x .080" and they come
                                                        with ¼” cropped corners and two 1/4" holes.<br/><br/>

                                                        The holes are aligned to fit both our light and heavy
                                                        duty posts.<br/><br/>

                                                        You can purchase Pipeline Blanks with either a Degreased
                                                        or White Vinyl Finish. </p>
                                                    <img src={pipeline} alt=""/>


                                                </Tab.Pane>

                                                <Tab.Pane eventKey="traffic">
                                                    <h5>Traffic Signs</h5>
                                                    <p>Our popular Traffic Signs meet highway specifications.
                                                        They are .080" aluminum either degreased or laminated with
                                                        3M reflective material. They come with 1.5" radius
                                                        corners and 3/8" holes to fit heavy duty posts.<br/><br/>

                                                       The following sizes are common
                                                        to traffic related signage:</p>
                                                    <ul>
                                                        <li> 30x45cm </li>
                                                        <li> 30x60cm </li>
                                                        <li> 45x60cm </li>
                                                        <li> 60x60cm </li>
                                                        <li> 60x75cm </li>
                                                        <li> 75x75cm </li>
                                                        <li> 90x90cm </li>
                                                        <li> 120x120cm </li>
                                                    </ul>
                                                    <img src={yield2} alt=""/>
                                                    <img src={stop} alt=""/>
                                                    <br/><br/>
                                                    <h6>
                                                        Our Traffic Signs can be finished in any of the following:
                                                    </h6>
                                                    <ul>
                                                        <li> Degreased Aluminum</li>
                                                        <li> 3M Engineer Grade Reflective</li>
                                                        <li> 3M High Intensity Prismatic Reflective</li>
                                                        <li>3M Diamond Grade Reflective  </li>
                                                    </ul>

                                                    <h6>
                                                        We also offer pre-printed Traffic Signs and are sale and install ready. You have your choice of the following:
                                                    </h6>
                                                    <ul>
                                                        <li> 60x60cm Stop Signs printed on High Intensity</li>
                                                        <li> 75x75x75cm Yield Signs printed on High Intensity </li>
                                                    </ul>
                                                    <p>Beyond the above options, we can also cut and laminate
                                                        custom sizes and colors to fit your needs. If you know
                                                        what you’d like to order, please request a quote.
                                                        If you’d like to chat about your options, feel free to
                                                        reach out.</p>
                                                    <img src={traffic1} alt=""/>
                                                    <img src={traffic2} alt=""/>
                                                </Tab.Pane>

                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </Layout>
        </MenuContextProvider>
    );
};

export default SignBlanks;
